import React, { FC, useEffect, useId, useRef, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import ProductCard from "./ProductCard";
import { Product, PRODUCTS } from "data/data";
import axios from 'axios';
import API_HOST from '../config/config';
import { useAuth } from '../contains/AuthContext';

export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: Product[];
}

const SectionSliderProductCard2: FC<SectionSliderProductCardProps> = ({
  className = "",
  itemClassName = "",
  headingFontClassName,
  headingClassName,
  heading,
  subHeading = "REY backpacks & bags",
  data = PRODUCTS.filter((_, i) => i < 8 && i > 2),
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  const { user } = useAuth();
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => { 
      try {
        axios.post(API_HOST+'/api/get-home-data',{
          nopaginate : '1',
          status : '1',
          order : 'desc',
          type:'best_seller_products',
          user_id:user?user.id:'0'
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setProducts(response.data.data.best_seller_products);
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });  
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  // useEffect(() => {
  //   console.log(products)
  // }, [products]);

  useEffect(() => {
    if (!sliderRef.current) {
      return () => {};
    }

    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [products, sliderRef, UNIQUE_CLASS]);

  return (
    <>
    <div className={`nc-SectionSliderProductCard ${className}`}>
      {products.length > 0 &&  
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading
          className={headingClassName}
          fontClass={headingFontClassName}
          rightDescText={subHeading}
          hasNextPrev
        >
          {heading || `New Arrivals`}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {products.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <ProductCard data={item} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      }
    </div>

    {/* --- skeletant-design --- */}
    <div className="skeletant-target hidden">
      <div className="skeletant-design ld-no-img h-[30px] max-w-[200px] mt-4 mb-4"></div>
      <div className="grid grid-cols-[1.3] sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5">

        <div className="flex">
          <div className="w-full">
            <div className="skeletant-design h-[300px]"></div>
            <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
            <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
            <div className="flex">
              <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
              <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
            </div>
          </div>

          <div className="block sm:hidden pl-2 w-[50px]">
            <div className="skeletant-design ld-no-img h-[300px]"></div>
            <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
            <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
            <div className="flex">
              <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
              <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block">
          <div className="skeletant-design h-[300px]"></div>
          <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
          <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
          <div className="flex">
            <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
            <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
          </div>
        </div>

        <div className="hidden md:block">
          <div className="skeletant-design h-[300px]"></div>
          <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
          <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
          <div className="flex">
            <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
            <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
          </div>
        </div>

        <div className="hidden xl:block">
          <div className="skeletant-design h-[300px]"></div>
          <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
          <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
          <div className="flex">
            <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
            <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
          </div>
        </div>

      </div>
    </div>
    {/* --- skeletant-design --- */}

    </>
    
  );
};

export default SectionSliderProductCard2;
