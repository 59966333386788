import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment } from "react";
import moment from 'moment';
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface ModalViewOrderProps {
  show: boolean;
  onCloseModalViewOrder: () => void;
  data?: any;
}

const ModalViewOrder: FC<ModalViewOrderProps> = ({
  show,
  onCloseModalViewOrder,
  data = [],
}) => {
  const detail = data;
  console.log(detail)
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50"
        onClose={onCloseModalViewOrder}
      >
        <div className="flex items-center justify-center h-full text-center px-2">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-flex xl:py-8 max-w-[500px] w-full max-h-full">
              <div
                className="flex-1 flex overflow-hidden max-h-full p-4 w-full text-left align-middle transition-all transform rounded-2xl bg-white 
              dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
              >
                <span className="absolute right-3 top-3 z-50">
                  <ButtonClose onClick={onCloseModalViewOrder} />
                </span>

                <div className="flex-1 overflow-y-auto hiddenScrollbar px-1">

                  <div className="flex flex-wrap">

                    <div className="text-custom-golden text-xl font-medium mt-3 mb-[-2px] flex-1 basis-full">
                      Order Detail
                    </div>

                    <div className="my-3 flex-1 basis-full">
                      <div className="text-sm text-[#94A3B4] mb-1">Order Id</div>
                      <div className="text-xl font-medium ">#{detail.order_number}</div>
                    </div>

                    <div className="my-3 flex-1 basis-6/12">
                      <div className="text-sm text-[#94A3B4] mb-1">Order Date</div>
                      <div className="text-sm  font-light">{moment(detail.created_at).format('DD MMM YYYY')}</div>
                    </div>
                    
                    {/*<div className="my-3 flex-1 basis-6/12">
                      <div className="text-sm text-[#94A3B4] mb-1">Delivered Date</div>
                      <div className="text-sm text-[white] font-light">28 Nov 2023</div>
                    </div>*/}
                    
                    <div className="my-3 flex-1 basis-6/12">
                      <div className="text-sm text-[#94A3B4] mb-1">Order Status</div>
                      <div className="text-sm  font-light capitalize">{((detail.status == 'new') ? 'Pending' : detail.status)}</div>
                    </div>
                    
                    <div className="my-3 flex-1 basis-6/12">
                      <div className="text-sm text-[#94A3B4] mb-1">Order Item(s)</div>
                      <div className="text-sm font-light">{detail.quantity}</div>
                    </div>

                  </div>

                  <hr className="border-slate-200 dark:border-slate-700 my-6" />

                  <div>
                    <div className="text-custom-golden text-xl font-medium mt-3 mb-[-2px] flex-1 basis-full">
                      Order Summary
                    </div>
                    <div className="my-3 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                      <div className="flex justify-between pb-4">
                        <span>Subtotal</span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                          AED {detail?.sub_total?.toFixed(2)}
                        </span>
                      </div>
                      <div className="flex justify-between py-4">
                        <span>Discount</span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                          AED {detail?.coupon?.toFixed(2)}
                        </span>
                      </div>
                      <div className="flex justify-between py-4">
                        <span>Shpping estimate</span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                          AED {detail?.shipping_charge}
                        </span>
                      </div>
                      <div className="flex justify-between py-4">
                        <span>Tax estimate</span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                          AED {detail?.tax?.toFixed(2)}
                        </span>
                      </div>
                      <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                        <span>Order total</span>
                        <span>AED {detail?.total_amount?.toFixed(2)}</span>
                      </div>
                    </div>
                  </div>

                  <hr className="border-slate-200 dark:border-slate-700 my-6" />
                                         
                  <div className="flex flex-wrap">
                    
                    <div className="text-custom-golden text-xl font-medium mb-[-2px] flex-1 basis-full">
                      Delivery details
                    </div>
                    <div className="my-3 flex-1 basis-full">
                      <div className="text-sm text-[#94A3B4] mb-1">Name</div>
                      <div className="text-sm  font-light">{detail.first_name + ' ' + detail.last_name}</div>
                    </div>
                    
                    <div className="my-3 flex-1 basis-6/12">
                      <div className="text-sm text-[#94A3B4] mb-1">Email Id</div>
                      <div className="text-sm  font-light">{detail.email}</div>
                    </div>
                    
                    <div className="my-3 flex-1 basis-6/12">
                      <div className="text-sm text-[#94A3B4] mb-1">Phone Number</div>
                      <div className="text-sm  font-light">{detail.phone_number}</div>
                    </div>

                    <div className="my-3 flex-1 basis-full">
                      <div className="text-sm text-[#94A3B4] mb-1">Address</div>
                      <div className="text-sm  font-light">{detail.address1}</div>
                    </div>

                  </div>
                 
                </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalViewOrder;
