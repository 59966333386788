import React, { FC } from "react";
import { Helmet } from "react-helmet-async";


import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionHero from "./SectionHero";
// import SectionFounder from "./SectionFounder";
// import SectionStatistic from "./SectionStatistic";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
// import SectionPromo3 from "components/SectionPromo3";
// import rightImg from "images/hero-right1.png";

import visionmission from "images/visionmission.png";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || The Body Bucket</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <section className="pt-16 pb-16 relative z-10">
          <div className="lagle-container">
              <div className="lagle-heading">About us</div>
              <div className="legal-content">
                    <p>Welcome to The Body Bucket, your premier destination for exquisite bath bombs and salts. Immerse yourself in luxury with our handcrafted products, proudly made in the heart of India and delivered to the UAE. Elevate your bathing experience with our meticulously crafted blends, designed to pamper and rejuvenate. Experience the essence of tradition and quality as we bring the artistry of Indian craftsmanship to your self-care routine. Indulge in the beauty of handmade excellence with The Body Bucket.</p>
              </div>

              {/* <div className="pt-10 md:pt-5 flex flex-col md:flex-row md:space-x-10 items-center relative">

                <div className="flex-grow">
                  <img className="w-full" src={visionmission} alt="" />
                </div>

                <div className="w-screen max-w-full xl:max-w-xl space-y-5 lg:space-y-7">
                  <div className="legal-content">
                    <div className="main-sub-lable">Vision</div>
                    <p>To be a globally recognized leader, inspiring positive change and innovation across diverse industries. The Body Bucket General Trading envisions a future where our comprehensive range of services transforms lives, businesses, and experiences, leaving an indelible mark on the world.</p>

                    <div className="pt-3"></div>

                    <div className="main-sub-lable">Mission</div>
                    <p>The Body Bucket General Trading is dedicated to delivering unparalleled excellence across Spice Trading, Organic Foods Handmade Bath and Body products, IT services, Investment Solutions, E-commerce, Handmade Bath and Body products and VR solutions. Our mission is to empower individuals and businesses through strategic, innovative, and sustainable practices. We are committed to fostering growth, embracing diversity, and exceeding expectations to create lasting value for our clients and communities.</p>
                  </div>
                </div>

              </div> */}

          </div>
      </section>

      {/* <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        />

        <SectionFounder />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic />

        <SectionPromo3 />
      </div> */}
    </div>
  );
};

export default PageAbout;
