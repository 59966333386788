import React, { FC, useEffect, useState } from "react";
//import imageRightPng from "images/hero-right.png";
//import imageRightPng2 from "images/hero-right-2.png";
//import imageRightPng3 from "images/hero-right-3.png";
// import imageRightPng4 from "images/hero-right-4.png";

import backgroundLineSvg from "images/Moon.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import axios from 'axios';
import API_HOST from '../../config/config';

interface Hero2DataType {
  id:number;
  image: string;
  heading: string;
  sub_heading: string;
  btn_text?: string;
  btn_link?: string;
}
export interface SectionHero2Props {
  className?: string;
}

// const DATA: Hero2DataType[] = [
//   // {
//   //   image: imageRightPng2,
//   //   heading: "Jewelry",
//   //   subHeading: "In this season, find the best 🔥",
//   //   btnText: "Explore now",
//   //   btnLink: "/",
//   // },
//   // {
//   //   image: imageRightPng3,
//   //   heading: "Grocery",
//   //   subHeading: "In this season, find the best 🔥",
//   //   btnText: "Explore now",
//   //   btnLink: "/",
//   // },
//   // {
//   //   image: imageRightPng,
//   //   heading: "Beauty",
//   //   subHeading: "In this season, find the best 🔥",
//   //   btnText: "Explore now",
//   //   btnLink: "/",
//   // },
//   // {
//   //   image: imageRightPng4,
//   //   heading: "Exclusive collection for everyone",
//   //   subHeading: "In this season, find the best 🔥",
//   //   btnText: "Explore now",
//   //   btnLink: "/",
//   // },
// ];
let TIME_OUT: NodeJS.Timeout | null = null;

const SectionHero2: FC<SectionHero2Props> = ({ className = "" }) => {
  // =================
  const [indexActive, setIndexActive] = useState(0);
  const [isRunning, toggleIsRunning] = useBoolean(true);
  const [isLoaing, setIsLoading] = useState(true);
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    const fetchData = async () => { 
      try {
        axios.post(API_HOST+'/api/get-home-data',{
          nopaginate : '1',
          status : '1',
          order : 'asc',
          type:'banners'
        })
        .then(response => {
          if(response.data.success === 0)
          {
            setBanners(response.data.data.banners);
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });  
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  useEffect(() => {
    setIsLoading(false)
  }, [banners]);
  useInterval(
    () => {
      handleAutoNext();
    },
    isRunning ? 5500 : null
  );
  //

  const handleAutoNext = () => {
    setIndexActive((state) => {
      if (state >= banners.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= banners.length - 1) {
        return 0;
      }
      return state + 1;
    });
    handleAfterClick();
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return banners.length - 1;
      }
      return state - 1;
    });
    handleAfterClick();
  };

  const handleAfterClick = () => {
    toggleIsRunning(false);
    if (TIME_OUT) {
      clearTimeout(TIME_OUT);
    }
    TIME_OUT = setTimeout(() => {
      toggleIsRunning(true);
    }, 1000);
  };
  // =================

  const renderItem = (banner:Hero2DataType,index: number) => {
    
    const isActive = indexActive === index;
    const item = banner;
    if (!isActive) {
      return null;
    }
    return (
      
      <div
        className={`nc-SectionHero2Item nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
        key={index}
      >
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-20 flex justify-center">
          {banners.map((banner:Hero2DataType,index) => {
            const isActive = indexActive === index;
            return (
              <div
                key={index}
                onClick={() => {
                  setIndexActive(index);
                  handleAfterClick();
                }}
                className={`relative px-1 py-1.5 cursor-pointer`}
              >
                <div
                  className={`relative w-20 h-1 shadow-sm rounded-md bg-white`}
                >
                  {isActive && (
                    <div
                      className={`nc-SectionHero2Item__dot absolute inset-0 bg-slate-900 rounded-md ${
                        isActive ? " " : " "
                      }`}
                    ></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <Prev
          className="absolute left-1 sm:left-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
          btnClassName="main-slider-btn w-12 h-12"
          svgSize="w-6 h-6"
          onClickPrev={handleClickPrev}
        />
        <Next
          className="absolute right-1 sm:right-5 top-3/4 sm:top-1/2 sm:-translate-y-1/2 z-10 !text-slate-700"
          btnClassName="main-slider-btn w-12 h-12"
          svgSize="w-6 h-6"
          onClickNext={handleClickNext}
        />

        {/* BG */}
        {/* <div className="absolute inset-0 bg-[#7E6E88]"> */}
          {/* <div className="absolute inset-0 bg-[#F7F0EA]"> */}
          <div className="absolute inset-0 bg-[#ffefd2]">
          <img
            className="absolute w-full h-full object-contain"
            src={backgroundLineSvg}
            alt="hero"
          />
        </div>

        <div className="main-slider-bx relative lg:container px-5 pb-0 pt-10 sm:pt-20 lg:py-20">
          <div
            className={`relative z-[1] w-full max-w-3xl space-y-8 sm:space-y-14 nc-SectionHero2Item__left`}
          >
            <div className="space-y-5 sm:space-y-6">
              <span className="nc-SectionHero2Item__subheading block text-base md:text-xl text-slate font-medium">
                {item.sub_heading}
              </span>
              <h2 className="vsdzgvsdgsrhgshrfhs nc-SectionHero2Item__heading font-semibold text-3xl sm:text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl !leading-[114%] text-slate-900">
                {item.heading}
              </h2>
            </div>

            <ButtonPrimary
              className="custom-green-btn nc-SectionHero2Item__button"
              sizeClass="py-3 px-5 sm:py-3 sm:px-5"
              href={item.btn_link as any} onClick={() => { if(item.btn_link) { javascript:void(0); }}}
            >
              <span>{item.btn_text}</span>
              <span>
                <svg className="w-5 h-5 ml-2.5" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </ButtonPrimary>
          </div>
          <div className="mt-[-50px] md:mt-0 md:absolute right-0 bottom-0 top-0 max-w-2xl xl:max-w-3xl 2xl:max-w-4xl">
            <img
              className="w-full h-full object-contain object-right-bottom nc-SectionHero2Item__image"
              src={item.image}
              alt={item.heading}
            />
          </div>
        </div>
      </div>
    );
  };

  return <>
  {banners.length == 0 &&
    <div className="skeletant-target">
      <div className="skeletant-design ld-no-radius h-[380px] w-full mb-8"></div>
    </div>
  }
  {banners.map((banner:Hero2DataType,index) => renderItem(banner,index))}</>;
};

export default SectionHero2;
